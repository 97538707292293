import {categories} from "./menu";

export const header = document.querySelector('.header')

//region mobile menu toggle
export const toggleButton = header.querySelector('.header__mobile-menu-toggle')
export const toggleButtonSvgSprite = toggleButton.querySelector('svg use')
export const menu = header.querySelector('.header__mobile-menu')

toggleButton.addEventListener('click', () => {
    if (categories.classList.contains('categories--open')) {
        toggleCategories()
    } else {
        toggleMenu()
    }
    toggleIcon()
})

export function toggleIcon() {
    const xlink = toggleButtonSvgSprite.getAttribute('xlink:href')
    const currentIcon = xlink.split('#')[1]
    const pathToIcon = xlink.split('#')[0]
    const iconToSet = currentIcon === 'burger' ? 'cross' : 'burger'

    toggleButtonSvgSprite.setAttribute('xlink:href', `${pathToIcon}#${iconToSet}`)
}

export function toggleMenu() {
    menu.classList.toggle('mobile-menu--open')
}

export function toggleCategories() {
    categories.classList.toggle('categories--open')
}

//endregion



