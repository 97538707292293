import {destroyFormInModal, KwForm} from "../forms";

const callback = document.querySelector('#modal-callback');
const callbackForm = callback.querySelector('.callback-form');
const success = callback.querySelector('.callback-success');
const fail = callback.querySelector('.callback-fail');
const button = callback.querySelector('.callback-form__button');

const formEl = callbackForm.querySelector('[data-modal-callback-form]');
const form = new KwForm(formEl);

form.form.addEventListener('submit', event => {
    event.preventDefault();
    form.touch();

    if (!form.hasErrors) {
        button.disabled = true;

        fetch('index.php?route=common/modal_callback/add', {
            method: 'POST',
            body: new FormData(formEl),
            cache: 'no-cache'
        })
        .then(response => {
            callbackForm.classList.add('visually-hidden');

            [fail, success][+response.ok].classList.remove('visually-hidden');

            button.disabled = false;

            form.destroy();
        });
    }
});

destroyFormInModal(callback, form, () => {
    callbackForm.classList.remove('visually-hidden');

    success.classList.add('visually-hidden');
    fail.classList.add('visually-hidden');
});
