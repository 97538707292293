import {destroyFormInModal, KwForm} from "../forms";

const login = document.querySelector('#modal-login')

const formEl = login.querySelector('[data-modal-login-form]')
const form = new KwForm(formEl)
formEl.addEventListener('submit', event => {
    event.preventDefault()
    form.touch()

    if (!form.hasErrors) {
        formEl.submit()
    }
})

destroyFormInModal(login, form)

