import Inputmask from "inputmask/lib/inputmask";

const phones = document.querySelectorAll('[data-phone]')
Inputmask('+9 (999) 999-99-99').mask(phones)

const filesets = document.querySelectorAll('[data-password-fieldset]')
filesets.forEach(fieldset => {
    const eye = fieldset.querySelector('[data-eye]')
    const input = fieldset.querySelector('input')
    const use = eye.querySelector('use')

    eye.addEventListener('click', () => {
        const inputType = input.getAttribute('type')
        input.setAttribute('type', inputType === 'password' ? 'text' : 'password')

        const [iconPath, iconName] = use.getAttribute('xlink:href').split('#')
        use.setAttribute(
            'xlink:href',
            iconName === 'eye-open' ? `${iconPath}#eye-close` : `${iconPath}#eye-open`
        )
    })
})
