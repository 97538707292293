export const sm = 1024

export function isTouch() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export function hasScrollbar() {
    return document.body.scrollHeight > window.innerHeight
}
