import {KwForm} from "../forms";
import {sm} from "../responsive";

const contact = document.querySelector('.contact')

if (contact) {
    const formEl = contact.querySelector('[data-contact-question-form]')
    const form = new KwForm(formEl)

    const successEl = contact.querySelector('.contact__success')
    const errorEl = contact.querySelector('.contact__error')
    const submitButton = formEl.querySelector('.question-form__submit')

    formEl.addEventListener('submit', event => {
        event.preventDefault()

        form.touch()

        if (!form.hasErrors) {
            submitButton.setAttribute('disabled', true)

            // const response = new Promise(resolve => {
            //     setTimeout(() => {
            //         resolve('success')
            //     }, 2000)
            // })
            const response = fetch('index.php?route=information/contact', {
                method: 'post',
                body: new FormData(formEl),
                cache: 'no-cache'
            })
            response.then(res => {
                submitButton.removeAttribute('disabled')
                formEl.classList.add('visually-hidden')

                console.log([errorEl, successEl][+res.ok])
                ;[errorEl, successEl][+res.ok].classList.remove('visually-hidden')

                form.destroy()

                return res.json()
            })
        }
    })


    const main = contact.querySelector('.contact__main')
    const map = contact.querySelector('.contact__map')
    const info = main.querySelector('.contact__info')

    changeMapLocation()
    window.addEventListener('resize', changeMapLocation)

    function changeMapLocation() {
        if (screen.width < sm) {
            info.insertAdjacentElement('afterend', map)
        } else {
            main.insertAdjacentElement('afterend', map)
        }
    }
}

