if (window) {
    window.kw = {
        ...window.kw,
        query: {
            set: (key, value) => {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set(key, value);

                const newPath = window.location.pathname + '?' + searchParams.toString();
                history.pushState(null, '', newPath);
            },
            get: (key) => {
                const searchParams = new URLSearchParams(window.location.search);

                return searchParams.get(key);
            },
            remove: (key) => {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.delete(key);

                const newPath = window.location.pathname + '?' + searchParams.toString();
                history.pushState(null, '', newPath);
            }
        }
    }
}
