if (window) {
    window.kw = {
        ...window.kw,
        cookie: {
            set: (key, value, options) => {
                options = {
                    path: '/',
                    ...options
                };

                if (options.expires instanceof Date) {
                    options.expires = options.expires.toUTCString();
                }

                let updatedCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);

                for (const optionKey in options) {
                    updatedCookie += '; ' + optionKey;

                    const optionValue = options[optionKey];

                    if (optionValue !== true) {
                        updatedCookie += '=' + optionValue;
                    }
                }

                document.cookie = updatedCookie;
            },
            get: (item) => {
                const matches = document.cookie.match(
                  new RegExp('(?:^|; )' + item.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
                );

                return matches ? decodeURIComponent(matches[1]) : undefined;
            },
            remove: (item) => {
                window.kw.cookie.set(item, '', {
                    ['max-age']: -1
                });
            }
        }
    }
}
