// Implementation of:
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_tabs

if (window) {
    window.kw = {
        ...window.kw,
        tabs: {
            show: (tabName, queryName = 'tab') => {
                // remove query
                if (queryName) {
                    window.kw.query.remove(queryName);
                }

                let i;
                let tabContent = document.getElementsByClassName("kw-tab-content");
                let tabLinks = document.getElementsByClassName("kw-tab-toggle");

                // set content hidden
                for (i = 0; i < tabContent.length; i++) {
                    tabContent[i].style.display = "none";
                }

                // set toggle inactive
                for (i = 0; i < tabLinks.length; i++) {
                    tabLinks[i].className = tabLinks[i].className.replace(" active", "");
                }

                // set necessary content & toggle
                let activeTab = document.getElementById('tab-' + tabName);
                let activeLink = document.getElementById('link-' + tabName);

                activeTab && (activeTab.style.display = "block");
                activeLink && (activeLink.className += " active");

                // set query
                if (queryName) {
                    window.kw.query.set(queryName, tabName);
                }
            }
        }
    };

    // set tab if query
    window.addEventListener('load', () => {
        const tabName = window.kw.query.get('tab');

        if (tabName) window.kw.tabs.show(tabName);
    });
}
