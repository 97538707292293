// kw
import './kw/cookie'
import './kw/modals'
import './kw/queryParams'
import './kw/scrollTo'
import './kw/select'
import './kw/tabs'

import './global'

// common
import './common/callback'
import './common/header'
import './common/login'
import './common/menu'
import './common/personal-select'
import './common/signup'

// information
import './information/contact'
import './information/special'

// blocks
import './blocks/book-item'
import './blocks/modal'

