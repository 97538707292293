/**
 * functions to use in validations
 * it can be used html for example
 * `data-kw-validator-notempty` will affect function called notempty
 * and so on
 * @type {{}}
 */
const validations = {
    notempty: input => !!input.value.length,
    isemail: input => /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value),
    maxlength: (input, maxLength) => input.value.length <= parseInt(maxLength),
    minlength: (input, minLength) => input.value.length >= parseInt(minLength),
    sameas: (input, compareInputName) => {
        const formElement = input.closest('form')
        const compareInput = formElement.querySelector(`[data-kw-input="${compareInputName}"]`)
        return compareInput.value === input.value
    },
    istrue: input => !!input.checked

}
const validationPrefix = 'kwValidator'

class KwField extends EventTarget {
    /**
     * contain all error in like [validationName]: boolean
     * @type {{}}
     */
    errors = {}

    /**
     * contain data for method
     * @see {initConnectedInputs}
     * @type {[]}
     */
    connectedInputs = []
    /**
     * contain information about does Field was touched
     * @type {boolean}
     */
    touched = false

    /**
     * placeholder that was in input when KwInput initialized
     * @type {string}
     */
    initialPlaceholder = ''

    constructor(input) {
        super()
        this.input = input
        this.initialPlaceholder = input.getAttribute('placeholder')

        this.input.addEventListener('input', this.onInputHandler.bind(this))
        this.input.addEventListener('blur', this.onBlurHandler.bind(this))

        this.initConnectedInputs()
    }

    onBlurHandler() {
        this.touch();
    }

    onInputHandler() {
        this.runValidation()
        this.applyDom(false)
        super.dispatchEvent(new Event('onInput'))
    }

    /**
     * initialize input what will call validation on current input
     * in html you need to write data-kw-connected-input="some1;some2;some3"
     */
    initConnectedInputs() {
        if ('kwConnectedInputs' in this.input.dataset) {
            const connectedInputNames = this.input.dataset.kwConnectedInputs.split(';')
            const form = this.input.closest('form')
            this.connectedInputs = connectedInputNames.map(
                connectedInputName => form.querySelector(`[data-kw-input="${connectedInputName}"]`)
            )

            this.connectedInputs.forEach(
                input => input.addEventListener('input', this.onInputHandler.bind(this))
            )
        }
    }

    /**
     * use this function to set touch to true for
     * Field and also preform additional operations
     */
    touch() {
        this.runValidation()
        this.applyDom(true)
    }

    /**
     * clear all event listeners of bonded input element
     * clear value and remove classes
     */
    destroy() {
        this.input.setAttribute('placeholder', this.initialPlaceholder)

        this.input.removeEventListener('input', this.onInputHandler)
        this.input.removeEventListener('blur', this.onBlurHandler)

        this.connectedInputs.forEach(
            input => input.removeEventListener('input', this.onInputHandler)
        )
        this.input.classList.remove('kw-touched', 'kw-invalid')
        this.touched = false
        this.input.value = ''
    }

    /**
     * collect all data attribute validations in
     * bound input element by
     * @see {validationPrefix}
     * and run relevant functions from
     * @see {validations}
     *
     * than populate
     * @see {errors} object
     */
    runValidation() {
        const dataset = Object.keys(this.input.dataset)

        const commonTypes = dataset
            .filter(type => type.includes(validationPrefix))
            .map(type => type.replace(validationPrefix, ''))
        commonTypes.forEach(
            type => {
                this.errors[type] = validations[type.toLowerCase()](this.input, this.input.dataset[validationPrefix + type])
            }
        )
    }

    /**
     * @returns {boolean} - has input any error
     * run only after
     * @see {runValidation}
     */
    get hasErrors() {
        return Object.values(this.errors).some(error => !error)
    }

    /**
     * get value based on input type
     */
    get value() {
        const type = this.input.getAttribute('type');
        if (type === 'text' || type === 'email' || type === 'password') {
            return this.input.value
        } else if (type === 'checkbox') {
            return this.input.checked
        }
    }

    /**
     * @param withTouch {boolean} - does it affect touch state of field
     *
     * run every time when need connect js instance with input in dom
     */
    applyDom(withTouch) {
        withTouch && this.input.classList.add('kw-touched')
        this.touched = withTouch

        if (this.hasErrors) {
            this.input.classList.add('kw-invalid')

            if (!this.errors.Notempty && this.touched) {
                this.input.setAttribute('placeholder', 'Обязательно для заполнения')
            }
        } else {
            this.input.classList.remove('kw-invalid')
            if ('kwValidatorNotempty' in this.input.dataset) {
                this.input.setAttribute('placeholder', this.initialPlaceholder)
            }
        }
    }
}

export class KwForm {
    inputFields = {}
    touched = false

    constructor(form) {
        this.form = form
        if (!form) {
            return
        }

        this.inputs = this.form.querySelectorAll('[data-kw-input]')

        this.updateInputs()
        Object.values(this.inputFields).forEach(field => {
            field.addEventListener('onInput', this.onInputHandler.bind(this))
        })
    }

    onInputHandler() {
        this.applyDom()
    }

    updateInputs() {
        this.inputFields = [...this.inputs].reduce((acc, el) => {
            acc[el.dataset.kwInput] = new KwField(el)
            return acc
        }, {})
    }

    destroy() {
        this.form.classList.remove('kw-touched')
        this.touched = false

        Object.values(this.inputFields).forEach(field => {
            field.destroy()
            field.removeEventListener('onInput', this.onInputHandler)
        })
    }

    touch() {
        this.form.classList.add('kw-touched')
        Object.values(this.inputFields).forEach(field => field.touch())
        this.applyDom()
    }

    get hasErrors() {
        return Object.values(this.inputFields)
            .some(field => field.hasErrors)
    }

    applyDom() {
        if (this.hasErrors) {
            this.form.classList.add('kw-invalid')
        } else {
            this.form.classList.remove('kw-invalid')
        }
    }

    get values() {
        const result = {}
        for (const inputFieldsKey in this.inputFields) {
            result[inputFieldsKey] = this.inputFields[inputFieldsKey].value
        }

        return result
    }
}

export function destroyFormInModal(modal, form, alsoFn = () => {
}) {
    const cross = modal.querySelector('.modal__cross')
    const overlay = modal.querySelector('.modal__overlay')
    const closingElements = [overlay, cross]
    closingElements.forEach(el => {
        el.addEventListener('click', () => {
            form.destroy()

            alsoFn()
        })
    })
}
