import {KwForm} from "../forms";

const page = document.getElementById('information-special');

if (page) {
    const callback = document.querySelector('#special-callback-form');
    const callbackForm = callback.querySelector('.kw-information-special-callback-form');
    const success = callback.querySelector('.kw-information-special-callback-message.success');
    const fail = callback.querySelector('.kw-information-special-callback-message.fail');
    const button = callback.querySelector('.kw-information-special-callback-button');

    const formEl = callback.querySelector('[data-modal-special-callback-form]');
    const form = new KwForm(formEl);

    form.form.addEventListener('submit', event => {
        event.preventDefault();
        form.touch();

        if (!form.hasErrors) {
            button.disabled = true;

            fetch('index.php?route=information/special/addCallback', {
                method: 'POST',
                body: new FormData(formEl),
                cache: 'no-cache'
            })
            .then(response => {
                callbackForm.classList.add('visually-hidden');

                [fail, success][+response.ok].classList.remove('visually-hidden');

                button.disabled = false;

                form.destroy();
            });
        }
    });
}
