import {destroyFormInModal, KwForm} from "../forms";

const personalSelectModal = document.querySelector('#modal-personal-select');

if (personalSelectModal) {
  const callbackForm = personalSelectModal.querySelector('.select-modal__form-view');
  const success = personalSelectModal.querySelector('.select-modal__form-view-success');
  const fail = personalSelectModal.querySelector('.select-modal__form-view-fail');
  const button = personalSelectModal.querySelector('.select-modal__submit');

  const formEl = personalSelectModal.querySelector('[data-modal-select-form]');
  const form = new KwForm(formEl);

  formEl.addEventListener('submit', event => {
    event.preventDefault();
    form.touch();

    if (!form.hasErrors) {
      button.disabled = true;

      fetch('index.php?route=common/personal_select/add', {
        method: 'POST',
        body: new FormData(formEl),
        cache: 'no-cache'
      })
        .then(response => {
          callbackForm.classList.add('visually-hidden');

          [fail, success][+response.ok].classList.remove('visually-hidden');

          button.disabled = false;

          form.destroy();
        });
    }
  })

  destroyFormInModal(personalSelectModal, form);
}
