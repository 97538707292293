if (window) {
  window.kw = {
    ...window.kw,
    scrollTo: (id, offset, queryName = 'anchor') => {
      // remove query
      if (queryName) {
        window.kw.query.remove(queryName);
      }

      window.scroll({
        top: document.getElementById(id).offsetTop + offset,
        left: 0,
        behavior: 'smooth'
      });

      // set query
      if (queryName) {
        window.kw.query.set(queryName, id);
      }
    }
  };

  // set tab if query
  window.addEventListener('load', () => {
    const id = window.kw.query.get('anchor');
    const isB2b = document.getElementById('information-b2b');

    let offset = 0;

    if (isB2b) {
      offset = -120;
    }

    const timeout = setTimeout(() => {
      if (id) window.kw.scrollTo(id, offset);

      clearTimeout(timeout);
    }, 100)
  });
}
