import {destroyFormInModal, KwForm} from "../forms";

const signup = document.querySelector('#modal-signup')

const formEl = signup.querySelector('[data-modal-signup-form]')
const form = new KwForm(formEl)

formEl.addEventListener('submit', event => {
    event.preventDefault()
    form.touch()

    if (!form.hasErrors) {
        formEl.submit()
    }
})

destroyFormInModal(signup, form)
