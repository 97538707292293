import {sm} from "../responsive";
import {toggleButton, toggleIcon, toggleMenu} from "./header"

const menu = document.querySelector('.menu')
export const categories = document.querySelector('.categories')

if (screen.width < sm) {
    menu.classList.add('menu--hide')
}

//<editor-fold desc="Menu hiding">
let prevScroll = window.pageYOffset
window.addEventListener('scroll', event => {
    if (categories.classList.contains('categories--open') || (window.pageYOffset < 70 && screen.width > sm)) {
        return
    }

    const currenScroll = window.pageYOffset;

    if (prevScroll > currenScroll) {
        menu.classList.remove('menu--hide')
    } else {
        menu.classList.add('menu--hide')
    }

    prevScroll = currenScroll
})

//</editor-fold>
//<editor-fold desc="Categories menu">
let categoriesItems = categories.querySelectorAll('.categories__list > li')

categoriesItems = [...categoriesItems].filter(item => item.querySelector('.categories__children'))
categoriesItems.forEach(item => {
    const topCategory = item.querySelector('.categories__item')
    const childrenCategories = item.querySelector('.categories__children')
    const arrow = item.querySelector('.categories__arrow')
    const border = item.querySelector('.categories__border')

    topCategory.addEventListener('click', event => {
        event.preventDefault()
        childrenCategories.classList.toggle('categories__children--open')
        arrow.classList.toggle('categories__arrow--rotated')
        border.classList.toggle('hide-on-mobile')
    })
})


window.addEventListener('click', event => {
    categories.classList.remove('categories--open')
})

const categoriesToggle = document.querySelector('[data-categories-toggle]')
categoriesToggle.addEventListener('click', event => {
    event.stopPropagation()

    toggleIcon()
    categories.classList.toggle('categories--open')
})

categories.addEventListener('click', event => event.stopPropagation())

//</editor-fold>

function ensureWidth(parentElement) {
    let totalRealWidth = 0;
    let parentElementBoundingRect = parentElement.getBoundingClientRect()
    let lowestLeft = parentElementBoundingRect.x;
    let highestRight = parentElementBoundingRect.width;
    for (let i = 0; i < parentElement.children.length; i++) {
        let {x, width} = parentElement.children[i].getBoundingClientRect();
        if (x < lowestLeft) {
            lowestLeft = x;
        }
        if (x + width > highestRight) {
            highestRight = x + width;
        }
    }
    totalRealWidth = highestRight - lowestLeft;
    parentElement.style.width = `${totalRealWidth}px`;
}

if (screen.width > sm) {
    const categoriesList = categories.querySelector('.categories__list')

    ensureWidth(categoriesList)

    window.addEventListener('resize', () => {
        ensureWidth(categoriesList)
    })
}


// window.addEventListener('resize', () => {
//     ensureWidth(categoriesList)
// })

