import {hasScrollbar, isTouch} from "../responsive";
import {header} from "../common/header";

if (window) {
    window.kw = {
        ...window.kw,
        openModal(modalId) {
            const modal = document.getElementById(modalId);

            window.kw.query.set('modal', modal.id);

            document.body.style.overflowY = 'hidden';

            if (!isTouch() && hasScrollbar()) {
                document.body.style.paddingRight = '16px'
                header.style.right = '16px'
            }

            modal.classList.add('modal--open');
        },
        toggleModal(modalId) {
            const modal = document.getElementById(modalId);
            modal.classList.toggle('modal--open');
        },
        closeModal(modal) {
            if (typeof modal === 'string') {
                modal = document.getElementById(modal);
            }

            modal.classList.remove('modal--open')
            window.kw.query.remove('modal')

            document.body.style.overflowY = 'auto'

            if (!isTouch() && hasScrollbar()) {
                document.body.style.paddingRight = '0px'
                header.style.right = '0px'
            }
        }
    }

    // set modal if query
    window.addEventListener('load', () => {
        const modalQuery = window.kw.query.get('modal');

        if (modalQuery) window.kw.openModal(modalQuery);
    });
}
